export const Constants = {
  CLOCK_INSTRUCTION_QUEUE: 'clock-instruction-queue',
  TASK_QUEUE: 'task-queue',
  MATCHES_QUEUE: 'matches-queue',
  COFFEE_MAKER_QUEUE: 'coffee-maker-queue',
  MONTHLY_PLAN_ID: 'pro-monthly-v3',
  ANNUAL_PLAN_ID: 'pro-annual-v3',
  ANNUAL: 'ANNUAL',
  MONTHLY: 'MONTHLY',

  STATUS_PENDING: 'pending',
  STATUS_LOADED: 'loaded',
  STATUS_EXEC_STAGE_1: 'exec_stage_1',
  STATUS_EXEC_STAGE_2: 'exec_stage_2',
  STATUS_EXEC_STAGE_3: 'exec_stage_3',
  STATUS_SUCCESS: 'success',
  STATUS_STOPPED: 'stopped',
  STATUS_ARCHIVED: 'archived',
  STATUS_ACTIVE: 'active',
  STATUS_NONE: 'none',
  STATUS_APPROVED: 'approved',
  STATUS_AWAITING_APPROVAL: 'awaiting_approval',
  STATUS_REJECTED: 'rejected',
  STATUS_FAILURE: 'failure',
  STATUS_DELETED: 'deleted',

  TASK_STANDARD_MATCHING: 'runStandardMatchingProgram',
  TASK_LOTTERY_MATCHING: 'runLotteryMatchingProgram',
  TASK_CROSS_AUDIENCE_MATCHING: 'runCrossAudienceMatchingProgram',
  TASK_COFFEE_MAKER: 'runCoffeeMakerProgram',
  TASK_DID_YOU_MEET_TO_QUESTIONS_SEND: 'sendDidYouMeetQuestionsSend',
  TASK_RUN_TURN_OFF_TICK_CLOCK: 'turnOffTickClock',
  TASK_RUN_TURN_ON_TICK_CLOCK: 'turnOnTickClock',
  TASK_EXECUTE_MISSED_TASK: 'executeMissedTasks',
  TASK_RELOAD_PROGRAM: 'reloadProgram',
  TASK_STORE_USERS: 'storeUsers',
  TASK_STORE_USERS_LAZY: 'storeUsersLazy',
  TASK_FETCH_CONV_INFO: 'fetchAndStoreConversationInfo',

  TASK_ENSURE_CHANNEL_IS_VALID: 'ensureChannelIsValid',
  TASK_SEND_PROGRAM_INTRO_CARD: 'sendProgramIntroCard',
  TASK_UPDATE_PROGRAM_INTRO_CARD: 'updateProgramIntroCard',
  TASK_PROCESS_AUDIENCE_ON_PERSONAL_INTRO_MESSAGING: 'processAudienceOnPersonalIntroMessaging',
  TASK_MESSAGE_UN_NOTIFIED_AUDIENCE_MEMBER: 'messageUnNotifiedAudienceMember',
  TASK_UPDATE_PROGRAM_PERSONAL_INTRO_MESSAGES: 'updateProgramPersonalIntroMessages',
  TASK_UPDATE_PERSONAL_INTRO_CARD: 'updatePersonalIntroCard',
  TASK_PROCESS_PROGRAM_USER_SETTINGS: 'processProgramUserSettings',
  TASK_REMATCH_USERS: 'rematchUsers',
  TASK_PROCESS_OLD_MATCH_ON_REMATCH: 'processOldMatchOnRematch',

  TASK_ASK_CROSS_GROUP_MEMBERSHIP: 'askTeamMembership',

  TASK_MESSAGE_ADMIN: 'messageAdmin',

  TASK_CONDITION_CHECK_CONV_INFO_BEFORE_MESSAGING_USER: 'checkConvInfoBeforeMessagingUser',

  TASK_OPT_IN_QUESTION_SEND: 'messageProgramUsersOptInQuestion',
  TASK_OPT_IN_DELETE_CARD: 'deleteOptInCardsOfUser',

  TASK_DID_YOU_MEET_REPORTING_PERIOD_END: 'didYouMeetReportingPeriodEnd',
  TASK_DELETE_ASK_MET_MATCH_CARD_OF_USER: 'deleteAskMetMatchCardsOfUser',

  TASK_SCHEDULE_ALL_ACCOUNT_HEALTH_SCORES: 'scheduleAllAccountHealthScores',
  TASK_CALCULATE_ACCOUNT_HEALTH_SCORE: 'calculateAccountHealthScore',

  MATCH_CARD_CONNECTED_CALENDAR_HEADER_IMAGE: 'image',
  MATCH_CARD_CONNECTED_CALENDAR_HEADER_TITLE_WITH_TOTAL_MATCH_MET_CYCLE: 'title_total_match_met_cycle',

  DEEP_LINK_START_GROUP_CHAT: 'start_group_chat',
  DEEP_LINK_MANUAL_SCHEDULE: 'manual_schedule',

  ADAPTIVE_CARD_SCHEMA_URL: 'http://adaptivecards.io/schemas/adaptive-card.json',
  COFFEE_MAKER_MENTION_GROUP_LIMIT: 200,

  MESSAGE_STATUS_GOOD: 'good',

  PRICING_PAGE_URL: 'https://coffeepals.com/pricing',

  PLATFORM_MS_TEAMS: 'MSTeams',
  PLATFORM_SLACK: 'slack',

  CALENDAR_GOOGLE: 'google_calendar',
  CALENDAR_MSTEAMS: 'microsoft_calendar',

  BASE_AUDIENCE_KEY: 'BaseAudience',

  PROGRAM_BASE_PROGRAM: 'BaseProgram',
  PROGRAM_LOTTERY_MATCHING: 'LotteryMatchingProgram',
  PROGRAM_STANDARD_MATCHING: 'StandardMatchingProgram',
  PROGRAM_CROSS_AUDIENCE_MATCHING: 'CrossAudienceMatchingProgram',
  PROGRAM_COFFEE_MAKER: 'CoffeeMakerProgram',

  AUDIENCE_MS_TEAMS: 'MSTeamsAudience',
  AUDIENCE_SLACK: 'SlackAudience',

  USER_SLACK: 'SlackUser',
  USER_MSTEAMS: 'MSTeamsUser',

  MATCHING_PROGRAM_TYPE_CROSS_GROUP: 'team',
  MATCHING_PROGRAM_TYPE_STANDARD: 'standard',

  USER_SETTINGS_BASE_PROGRAM: 'BaseProgramUserSettings',
  USER_SETTINGS_MATCHING_PROGRAM: 'MatchingProgramUserSettings',
  USER_SETTINGS_COFFEE_MAKER_PROGRAM: 'CoffeeMakerProgramUserSettings',

  PROGRAM_TEMPLATE_BASE: 'BaseProgramTemplate',
  PROGRAM_TEMPLATE_STANDARD_MATCHING: 'StandardMatchingProgramTemplate',
  PROGRAM_TEMPLATE_LOTTERY_MATCHING: 'LotteryMatchingProgramTemplate',
  PROGRAM_TEMPLATE_CROSS_AUDIENCE_MATCHING: 'CrossAudienceMatchingProgramTemplate',
  PROGRAM_TEMPLATE_COFFEE_MAKER: 'CoffeeMakerProgramTemplate',

  PROGRAM_USER_SETTINGS_BASE: 'BaseProgramUserSettings',
  PROGRAM_USER_SETTINGS_MATCHING: 'MatchingProgramUserSettings',
  PROGRAM_USER_SETTINGS_COFFEE_MAKER: 'CoffeeMakerProgramUserSettings',

  TERMS_OF_SERVICE_LINK: 'https://coffeepals.com/terms-of-service',
  PRIVACY_POLICY_LINK: 'https://coffeepals.com/privacy',
  SUPPORT_PAGE_LINK: 'https://coffeepals.com/support',
  HELP_CENTER_LINK: 'https://help.coffeepals.com/en',
  ANNOUNCING_COFFEEPALS_LINK:
    'https://help.coffeepals.com/en/article/how-to-announce-coffeepals-to-your-organization-1xkq72z/',
  COFFEEPALS_APP_TEAMS_STORE_LINK: 'https://teams.microsoft.com/l/app/0905c41d-9f67-4ab7-8d94-4e2da3d2ff08',

  TRIAL_PERIOD_DAYS: 14,
  NODE_ENV_PRODUCTION: 'production',

  MATCH_SCHEDULE_AUTOMATED: 'automated_calendar',
  MATCH_SCHEDULE_CONNECTED: 'connected_calendar',
  MATCH_SCHEDULE_ASSISTED: 'assisted_calendar',

  MATCH_MESSAGE_TYPE_DEFAULT: 'Custom',
  MATCH_MESSAGE_DEFAULT: `Hey! You've been set up for a virtual coffee chat with a colleague. These chats are a great way to stay connected with your coworkers, build relationships, and get to know each other, even when we're working remotely. So, let's schedule a virtual meeting, grab a cup of coffee, and have a chat and get to know each other better.`,
  COFFEE_MAKER_PROMPT_TEXT_LENGTH_LIMIT: 500,

  WEEKDAYS: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],

  AWS_S3_FOLDER_COFFEEMAKER: 'coffeemaker',
  AWS_S3_FOLDER_INTRO_MESSAGES: 'introduction-messages',
  AWS_S3_FOLDER_TEMPLATE_IMAGES: 'template-images',
  AWS_S3_FOLDER_USER_AVATARS: 'Avatars',
  AWS_S3_PUBLIC_BUCKET_URL: 'https://coffeepals-public.s3.us-west-1.amazonaws.com',
  AWS_S3_FOLDER_SLACK: 'slack',

  NA: 'n_a',

  OPT_IN_DISABLE_ANSWER: 'disable',

  MICROSOFT_LOGIN_METHOD: 'microsoft',
  TEAMS_LOGIN_METHOD: 'teams',
  JWT_LOGIN_METHOD: 'jwt',
  SLACK_LOGIN_METHOD: 'slack',

  MET_MATCH_RESULT_MET: 'met',
  MET_MATCH_RESULT_NOT_MET: 'not_met',
  MET_MATCH_RESULT_NO_RESPONSE: 'no_response',
  MET_MATCH_RESULT_SCHEDULED: 'scheduled',

  MET_MATCH_REPLY_YES: 'yes',
  MET_MATCH_REPLY_NO: 'no',
  MET_MATCH_REPLY_NO_RESPONSE: 'no_response',
  MET_MATCH_REPLY_SCHEDULED: 'scheduled',

  ENV_RELEASE_MODE: ['staging', 'release', 'prod'],
  PROFILE_BIO_MAX_LENGTH: 500,

  USER_DELETED: 'User Deleted',

  RESPONSE_SUCCESS: 'success',
  RESPONSE_CONSENT_REQUIRED: 'consent_required',
  RESPONSE_Network_ERROR: 'network_error',

  ACCESS_HEADER: 'access-control-expose-headers',

  // MS errors
  MS_ERROR_MEMBER_NOT_FOUND_IN_CONVERSATION: 'MemberNotFoundInConversation',
  MS_ERROR_BOT_DISABLED_BY_ADMIN: 'BotDisabledByAdmin',
}
